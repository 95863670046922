let DB

export default class LocalDB {
  private DB_VERSION: number = 1;
  private DB_NAME = 'leakcheckccdb';
  private store: string = '';

  constructor(store: string) {
      this.store = store
    }

  async getDb() {
      return new Promise((resolve, reject) => {
			if (DB) { return resolve(DB) }
        let request = window.indexedDB.open(this.DB_NAME)
			
			request.onerror = e => {
          reject('Error')
			};

        request.onsuccess = (e:any) => {
          DB = e.target.result
				resolve(DB)
			};

        request.onupgradeneeded = (e:any) => {
          let db = e.target.result
				db.createObjectStore(this.store, { autoIncrement: true, keyPath: 'id' })
			};
      })
	}
    async delete (item:any) {
		const db:any = await this.getDb()

		return new Promise(resolve => {
			const trans = db.transaction([this.store], 'readwrite')
			trans.oncomplete = () => {
          resolve()
			};

        let store = trans.objectStore(this.store)
			store.delete(item.id)
		});
  }
  async deleteAll() {
    let db: any = await this.getDb();

      return new Promise(resolve => {
			const trans = db.transaction([this.store], 'readwrite')
			trans.oncomplete = () => {
          resolve()
			};

        trans.onerror = function () {
          resolve()
			};
        trans.onblocked = function () {
          resolve()
			};

        let store = trans.objectStore(this.store)
            store.clear()
		}).catch(e=> {
			console.error(e);
			
		});
    }

    async CreateObjectStore (increment = false) {
      return new Promise(resolve => {
			let request = indexedDB.open(this.DB_NAME)
			request.onsuccess = async (e:any) => {
          var database = e.target.result
				var names:DOMStringList = database.objectStoreNames
				let hasIt = names.contains(this.store)
				if(hasIt) {
            let db:any = await this.getDb()
					let trans = db.transaction([this.store], 'readonly')
					let store = trans.objectStore(this.store)
					let autoIncrement = store.autoIncrement
					if(increment && !autoIncrement) {
              await this.deleteDB().catch(e => {
                return null
						});
              db = await this.getDb()
						hasIt = false
					}
            if (hasIt)
              return resolve()
				}

        var version = parseInt(database.version);
          database.close()
				var secondRequest = indexedDB.open(this.DB_NAME, version + 1)
				secondRequest.onupgradeneeded = (e:any) => {
            var database = e.target.result
					var jsonObj = {
              keyPath: 'id',
              autoIncrement: false
            };
            if (increment) {
              jsonObj.autoIncrement = true
					}
            database.createObjectStore(this.store, jsonObj)
				};
          secondRequest.onsuccess = function (e:any) {
            e.target.result.close()
					resolve(true)
				}
          secondRequest.onerror = function () {
            resolve(true)
				};
          secondRequest.onblocked = function () {
            resolve(true)
				}
        }
      }).catch(e => {
        return null
		});
    }

  async deleteDB() {
      var req = indexedDB.deleteDatabase(this.DB_NAME)
        req.onsuccess = function () {
        console.log('Deleted database successfully');
      };
      req.onerror = function () {
        console.log("Couldn't delete database")
        };
      req.onblocked = function () {
        console.log("Couldn't delete database due to the operation being blocked")
        };
  }
  async getAllItems() {
      let db:any = await this.getDb()

		return new Promise(resolve => {
			const trans = db.transaction([this.store], 'readonly')
			trans.oncomplete = () => {
          resolve(items)
			};

        let store = trans.objectStore(this.store)
            
            let items = store.getAll()
            

		}).catch(e => {
        console.error(e)
			return null
		});
  }
    async getItem (key:string) {
      try {
        let db:any = await this.getDb()

			return new Promise(resolve => {
				const trans = db.transaction([this.store], 'readonly')
				trans.oncomplete = () => {
            resolve(items.result)
				};


          let store = trans.objectStore(this.store)
				
				let items = store.get(key)

			}).catch(e => {
          return null
			});
      } catch (e) {
        return null
		}
    }

    async save (item:any) {
        const db:any = await this.getDb()

		return new Promise(resolve => {
			const trans = db.transaction([this.store], 'readwrite')
			trans.oncomplete = () => {
          resolve()
			};

        trans.onerror = function (event) {
          // Do something with the error
      };
      trans.onsuccess = function(event) {};

      let store = trans.objectStore(this.store);
        store.put(item)

		}).catch(e => {

      })
	
  }
}
