





















































































































































































































































// import alertS from '../services/AlertService/AlertService';
import rService from '@/services/RequestService/RequestServiceSetup';
import LocalDB from '../services/LocalDB';

export default {
  data() {
    return {
      rowsPerPageItems: [10, 20, 30, 50],
      encryption: [],
      encryptionTypes: [],
      dateRegister: false,
      totalLeakedEmailsLeakCheck: 0,
      totalLeakedUsernamesLeakCheck: 0,
      db: new LocalDB('breaches'),
      sources: [
        {
          value: 'orSelector',
          name: 'OR (At least one)',
        },
        {
          value: 'HIBP',
          name: 'haveibeenpwned.com',
        },
        {
          value: 'LeakCheck',
          name: 'leakcheck.io',
        },
        {
          value: 'Dehashed',
          name: 'dehashed.com',
        },
        {
          value: 'Vigilante.pw',
          name: 'vigilante.pw',
        },
      ],
      reloadingList: false,
      sourceLeak: [],
      pagination: {},
      breaches: [],
      lastUpdate: '',
      realTime: '',
      realTimeInterval: null,
      namesBreaches: [],
      infoCompromisedSelected: [],
      infoCompromised: [],
      loading: true,
      typeOfLeak: [],
      search: '',
      value: '',
      totalLeaked: 0,
      totalLeakedDehashed: 0,
      safe: false,
      // headers: [],
      allValues: [],
      values: [],
      types: [],
      jsonDates: {
        BreachDate: 'Leak Date',
        AddedDate: 'Registered',
        ModifiedDate: 'Last Update',
      },
      dateStart: '',
      dateEnd: '',
      menu: false,
      modalStart: false,
      modalEnd: false,
      menu2: false,
    };
  },
  components: {
    Description: () => import('@/components/BreachComponents/Description.vue'),
    DialogBreach: () => import('@/components/BreachComponents/DialogBreach.vue'),
  },
  beforeDestroy() {
    if (this.realTimeInterval) clearInterval(this.realTimeInterval);
  },
  methods: {
    loadBreachCreated() {
      const router = this.$router.currentRoute;
      if (router.params && router.params.breach) {
        this.$store.commit('breachPopup', router.params.breach);
      }
    },
    resetFilters() {
      this.dateStart = '';
      this.dateEnd = '';
      this.search = '';
      this.typeOfLeak = [];
      this.encryption = [];
      this.dateRegister = false;
      this.infoCompromisedSelected = [];
    },
    filterNow() {
      this.values = this.allValues.filter((el) => {
        return this.filterTypeOfLeaks(el.Name, el);
      });
    },
    filterTypeOfLeaks(value, item) {
      let { search, leakTypes, dateStart, dateEnd, infoCompromised, sourceLeak, onlyDate, encryption } = this.propsSearchItem;
      if (typeof search !== 'string') search && search.Name ? (search = search.Name) : (search = '');
      const breachDate = this.$moment(item.BreachDate, 'YYYY-MM-DD');
      if (leakTypes.length) {
        const keys = Object.keys(item);
        const find = keys.find((key) => {
          const val = item[key];
          return leakTypes.includes(key) && val == 0;
        });
        if (leakTypes.includes('UnVerified')) {
          const valVerified = item.IsVerified;
          if (valVerified) return false;
        }
        if (find != undefined) return false;
      }
      if (sourceLeak.length) {
        if (sourceLeak.includes('orSelector')) {
          const isOnlyOr = item.Sources.length == 1 && item.Sources[0] == 'orSelector';
          if (item.Sources.length && !isOnlyOr) {
            const find = item.Sources.find((source) => {
              return (sourceLeak as string[]).indexOf(source) != -1;
            });
            if (find === undefined) return false;
          }
        } else {
          const chk = this._.isEmpty(this._.xor(sourceLeak, item.Sources));
          if (chk == false) return false;
        }
      }
      if (dateStart || dateEnd || onlyDate) {
        const isValidDate = this.$moment(breachDate).isValid();
        if (!isValidDate) return false;
      }
      if (dateStart) {
        if (this.$moment(dateStart).isAfter(breachDate)) return false;
      }
      if (dateEnd) {
        if (this.$moment(dateEnd).isBefore(breachDate)) return false;
      }
      if (infoCompromised && infoCompromised.length) {
        const find = infoCompromised.find((info) => {
          return item.DataClasses.includes(info);
        });
        if (find == undefined) return false;
      }
      if (encryption.length) {
        if (item.extra && item.extra['Detected Hashing Algorithms']) {
          if (!encryption.includes(item.extra['Detected Hashing Algorithms'])) return false;
        } else {
          return false;
        }
      }
      if (search == undefined || search == '') return true;
      return value != null && search != null && typeof value === 'string' && item.Name.toLowerCase().includes(search.toLowerCase());
    },
    processDateDay(date: string) {
      if (!date) return 'Unknown';
      return this.$moment(date).format('YYYY-MM-DD');
    },
    timeAgo(date: string) {
      if (!date) return '';
      return this.$moment(date).fromNow();
    },
    processDate(date: string) {
      if (!date) return 'Unknown';
      return this.$moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
    formatNumber(x: number) {
      if (!x) return 'Unknown';
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    formatNumberDiff(x: number, y: number) {
      return (x - y).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    processColor(name: string) {
      let color;
      switch (name) {
        case 'LeakCheck':
          color = '#5446F8';
          break;
        case 'HIBP':
          color = '#3a9ac4';
          break;
        case 'Dehashed':
          color = 'grey';
          break;
        case 'Vigilante.pw':
          color = '#42A5F5';
          break;
        default:
          break;
      }
      return color;
    },
    openFullInfoPopup(Name: string) {
      this.$store.commit('breachPopup', Name);
      this.$router
        .push({
          name: 'breaches',
          params: {
            breach: Name,
          },
        })
        .catch();
    },
    async reloadList() {
      await this.loadBreaches(true);
    },
    async getBreaches(reload = false) {
      await this.db.CreateObjectStore(true);
      const allBreachesDB: any = await this.db.getAllItems();
      if (allBreachesDB && allBreachesDB.result.length && allBreachesDB.result[0].lastRequested && reload == false) {
        this.lastUpdate = allBreachesDB.result[0].lastRequested;
        this.reloadRealTime();
        return allBreachesDB.result;
      } else {
        await this.db.deleteAll();
        const res = await rService.post('breaches', { app: true }, false, true);
        res.forEach((element, index) => {
          element.id = index;
          if (index == 0) {
            this.lastUpdate = new Date();
            element.lastRequested = this.lastUpdate;
          }
          this.db.save(element);
        });
        this.reloadRealTime();
        return res;
      }
    },
    reloadRealTime() {
      const realTime = this.$moment.utc(this.$moment(new Date(), 'DD/MM/YYYY HH:mm:ss').diff(this.$moment(this.lastUpdate, 'DD/MM/YYYY HH:mm:ss')));
      // .format('HH:mm:ss');
      this.realTime = realTime.format('HH:mm:ss');
      if (this.realTimeInterval) clearInterval(this.realTimeInterval);
      this.realTimeInterval = setInterval(() => {
        realTime.add(1, 'second');
        this.realTime = realTime.format('HH:mm:ss');
      }, 1000);
    },
    initialValues() {
      this.reloadingList = true;
      this.totalLeaked = 0;
      this.totalLeakedDehashed = 0;
      this.totalLeakedEmailsLeakCheck = 0;
      this.totalLeakedUsernamesLeakCheck = 0;
      this.values = [];
      this.allValues = [];
      this.types = [];
      this.breaches = [];
      this.loading = true;
      this.infoCompromised = [];
    },
    async loadBreaches(reload = false) {
      this.initialValues();
      this.breaches = await this.getBreaches(reload);
      const types = ['UnVerified', 'IsVerified', 'IsSensitive', 'IsFabricated', 'IsRetired', 'IsSpamList'];
      const notAdd = ['LogoPath', 'Domain', ...types, 'id_breach', 'leak_source', 'NameDehashed', 'DateDehashed', 'DescriptionDehashed', 'CountDehashed', 'DataDehashed', 'PwnCountDehashed', 'DehashedOnly'];
      const dates = ['BreachDate', 'AddedDate', 'ModifiedDate'];
      const jsonTypes = {
        UnVerified: 'Unverfied',
        IsVerified: 'Verified',
        IsSensitive: 'Sensitive',
        IsFabricated: 'Fabricated',
        IsRetired: 'Retired',
        IsSpamList: 'Spam List',
      };
      this.types = types.map((el) => {
        return {
          name: jsonTypes[el],
          value: el,
        };
      });
      if (this.breaches) {
        this.breaches.forEach((breach) => {
          delete breach.id;
          if (breach.DataClasses) {
            if (breach.DataClasses.length) {
              breach.DataClasses.forEach((data) => {
                if (!this.infoCompromised.includes(data)) this.infoCompromised.push(data);
              });
            }
            breach.passCompromised = breach.DataClasses.includes('Passwords');
          }

          if (!this.namesBreaches.includes(breach.Name)) this.namesBreaches.push(breach.Name);

          this.totalLeaked += parseInt(breach.PwnCount);
          this.totalLeakedDehashed += parseInt(breach.PwnCountDehashed);

          this.totalLeakedEmailsLeakCheck += parseInt(breach.EmailCount);
          this.totalLeakedUsernamesLeakCheck += parseInt(breach.UserCount);

          /** If Breach has Vigilante.pw */
          if (breach.extra) {
            if (!this.encryptionTypes.includes(breach.extra['Detected Hashing Algorithms'])) {
              this.encryptionTypes.push(breach.extra['Detected Hashing Algorithms']);
            }
            if (!breach.Sources.includes('Vigilante.pw')) {
              breach.Sources.push('Vigilante.pw');
            }
          }

          if (breach.Sources[0] == ['Vigilante.pw']) {
            breach.BreachDate = breach.extra['Dump Date'];
          }

          this.values.push(breach);
        });
        if (!this.allValues.length) this.allValues = [...this.values];
        this.loading = false;
        this.reloadingList = false;
      }
    },
  },
  mounted() {
    this.loadBreaches();
  },
  watch: {
    propsSearchItem: {
      handler(val) {
        this.filterNow();
      },
      deep: true,
    },
  },
  computed: {
    propsSearchItem() {
      return {
        encryption: this.encryption,
        search: this.search,
        leakTypes: this.typeOfLeak,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        sourceLeak: this.sourceLeak,
        infoCompromised: this.infoCompromisedSelected,
        onlyDate: this.dateRegister,
      };
    },
    headers() {
      return [
        {
          text: 'Name',
          value: 'Name',
          padding: '0px',
          class: 'auto',
          width: '250px',
        },
        {
          text: 'Title',
          value: 'Title',
          width: 'auto',
        },
        {
          text: 'Leak Date',
          value: 'BreachDate',
          width: '130px',
        },
        {
          text: 'Registered',
          value: 'AddedDate',
          width: '130px',
        },
        {
          text: 'Last Update',
          value: 'ModifiedDate',
          width: '130px',
        },
        {
          text: 'Leaked Accounts',
          value: 'PwnCount',
          width: '150px',
        },
        ,
        {
          text: 'Dehashed Accounts',
          value: 'EmailCount',
          width: 'auto',
        },
        {
          text: 'Description',
          value: 'Description',
          width: '150px',
        },
        {
          text: 'Compromised Info',
          value: 'DataClasses',
          width: '200px',
        },
        {
          text: 'Sources',
          value: 'Sources',
          width: 'auto',
        },
      ];
    },
    getTarget() {
      if (this.desktop) return '#invisible_taget';
      else return '.v-data-table__wrapper';
    },
    propsSearch: function () {
      return JSON.stringify({
        search: this.search,
        leakTypes: this.typeOfLeak,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        sourceLeak: this.sourceLeak,
        infoCompromised: this.infoCompromisedSelected,
      });
    },
  },
};
